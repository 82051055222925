import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Header from "containers/Contact/Header"
import FormSection from "containers/Homepage/FormSection/FormSection"

const Contact = () => {
  const SITE_NAME = "Kontakt"
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    SITE_NAME,
  ]
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle={SITE_NAME}>
      <Header />
      <Hr mb={4} mt={6} />
      <Font42 mb={4}>{SITE_NAME}</Font42>
      <FormSection />
    </Layout>
  )
}

export default Contact
