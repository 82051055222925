import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Font18 from "components/Fonts/Font18"
import { companyData } from "../../constants/companyData"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      mediraBig: file(name: { eq: "medira-logo-big" }) {
        childImageSharp {
          gatsbyImageData(
            backgroundColor: "transparent"
            quality: 95
            placeholder: NONE
          )
        }
      }
    }
  `)

  const image = data.mediraBig.childImageSharp.gatsbyImageData
  return (
    <Grid container mt={12} spacing={4}>
      <Grid
        item
        xs={12}
        md={7}
        container
        justifyContent={{ xs: "center", md: "start" }}
        alignItems={"center"}
      >
        <GatsbyImage image={image} alt="Logo Medira" />
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        container
        justifyContent={{ xs: "center", md: "start" }}
        flexDirection={"column"}
      >
        <Font18 fw={900} mb={0} textAlign={{ xs: "center", md: "left" }}>
          MEDIRA Pflege Sp. z o.o. Sp.k.
        </Font18>
        <Font18
          component={"a"}
          href={`tel:${companyData.telephone}`}
          mb={0}
          textAlign={{ xs: "center", md: "left" }}
          sx={{
            textDecoration: "none",
          }}
        >
          Tel.: {companyData.telephoneFormatted}
        </Font18>
        <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
          Email: {companyData.mail}
        </Font18>
        <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
          NIP: {companyData.nip}
        </Font18>
        <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
          REGON: {companyData.regon}
        </Font18>
        <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
          KRS: {companyData.krs}
        </Font18>
        <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
          KATOWICE - Centrala
        </Font18>
        <Font18 textAlign={{ xs: "center", md: "left" }}>
          {companyData.address}
        </Font18>
        <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
          Godziny otwarcia biura: pon-pt 8:00-16:00
        </Font18>
        <Font18 textAlign={{ xs: "center", md: "left" }}>
          Numer wpisu do KRAZ: 24419
        </Font18>
      </Grid>
    </Grid>
  )
}

export default Header
